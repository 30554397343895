<template>
  <TopChartsSidebarFilter
    :selected-date-filter="selectedDateFilter"
    :selected-gateways-list="selectedGatewaysList"
    :selected-currencies-list="selectedCurrenciesList"

    @apply-date-changes="applyDateFilter"
    @apply-gateways-changes="applyGatewaysFilter"
    @apply-currencies-changes="applyCurrenciesFilter"

    @discard-gateways-changes="discardGatewaysFilter"
    @discard-currencies-changes="discardCurrenciesFilter"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TopChartsSidebarFilter from '@/views/TopChartsLayout/TopChartsSidebarFilter';

export default {
  name: 'TopChartsCountriesFilter',

  components: {
    TopChartsSidebarFilter,
  },

  computed: {
    ...mapGetters('topCountriesStore', {
      selectedDateFilter: 'selectedDateFilter',
      selectedGatewaysList: 'selectedGatewaysList',
      selectedCurrenciesList: 'selectedCurrenciesList',
    }),
  },

  methods: {
    ...mapActions('topCountriesStore', {
      setDateFilter: 'setDateFilter',
      setGatewaysFilter: 'setGatewaysFilter',
      setCurrenciesFilter: 'setCurrenciesFilter',

      loadTopCountries: 'loadTopCountries',
    }),

    applyDateFilter({ from, to }) {
      this.setDateFilter({
        from, to,
      })
        .then(this.loadTopCountries());
    },

    applyGatewaysFilter(changes) {
      this.setGatewaysFilter(changes)
        .then(this.loadTopCountries());
    },

    discardGatewaysFilter() {
      this.setGatewaysFilter([])
        .then(this.loadTopCountries());
    },

    applyCurrenciesFilter(changes) {
      this.setCurrenciesFilter(changes)
        .then(this.loadTopCountries());
    },

    discardCurrenciesFilter() {
      this.setCurrenciesFilter([])
        .then(this.loadTopCountries());
    },
  },
};
</script>
